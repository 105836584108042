import Footer from "./components/Footer"
import Header from "./components/Header"
import Main from "./components/Main"
import About from "./components/About"
import Price from "./components/Price"
import LatestNews from "./components/LatestNews"
import Games from "./components/Games"
import Contact from "./components/Contacts"
import Rule from "./components/Rules"
import AVArena from "./components/AVArena"
import AVClub from "./components/AVClub"

import React, {useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';

function scrollToTop() {
  const location = useLocation();

  useEffect(() => {
    scroller.scrollTo('top', {
      duration: 1000,
      smooth: true
    });
  }, [location]);
}

function HomePage1() {
  scrollToTop();
  return (
    <div className="home_page" id="top">
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

function AboutPage() {
  scrollToTop();
  return (
    <div className="about_page" id="top">
      <Header />
      <About />
      <Footer />
    </div>
  );
}

function PricePage() {
  scrollToTop();
  return (
    <div className="price_page" id="top">
      <Header />
      <Price />
      <Footer />
    </div>
  );
}

function LatestNewsPage() {
  scrollToTop();
  return (
    <div className="latest_news_page" id="top">
      <Header />
      <LatestNews />
      <Footer />
    </div>
  );
}

function GamesPage(){
  scrollToTop();
  return(
    <div className="games_page" id="top">
      <Header />
      <Games />
      <Footer />
    </div>
  )
}

function ContactPage(){
  scrollToTop();
  return(
    <div className="contact_page" id="top">
      <Header />
      <Contact />
      <Footer />
    </div>
  )
}

function RulesPage(){
  scrollToTop();
  return(
  <div className="rules_page" id="top">
    <Header />
    <Rule />
    <Footer />
  </div>
  )
}

function ArenaPage(){
  scrollToTop();
  return(
  <div className="arena_page" id="top">
    <Header />
    <AVArena />
    <Footer />
  </div>
  )
}

function ClubPage(){
  scrollToTop();
  return(
  <div className="club_page" id="top">
    <Header />
    <AVClub />
    <Footer />
  </div>
  )
}

function App() {
  return (
    <div className="wrapper">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage1 />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/price" element={<PricePage />} />
          <Route path="/news" element={<LatestNewsPage />} />
          <Route path="/games" element={<GamesPage />} />
          <Route path="/contacts" element={<ContactPage />} />
          <Route path="/rules" element={<RulesPage/>} />
          <Route path="/arena" element={<ArenaPage/>} />
          <Route path="/club" element={<ClubPage/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
