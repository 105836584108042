import React from 'react'
import { Link } from 'react-router-dom'
import News from './sections/News'
import decor from '../img/dash_first.png'
import decor_3 from '../img/dash_eleventh.png'
import PriceArena from './sections/PriceArena'
import PriceClub from './sections/PriceClub'


export default function Main() {
  return (
        <div className="price_page_wrapper">
            <section className="banner">
                <div className="banner_wrapper">
                    <p><Link to="/">Главная</Link> - Цены </p>
                    <h3>ЦЕНЫ</h3>
                </div>
            </section>
            <section className="bar_2"></section>
            <PriceArena/>
            <section className="bar"></section>
            <PriceClub/>
            <News />
        </div>
    )
  }
