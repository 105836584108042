import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import Table from 'rc-table';
import decor from '../../img/dash_first.png'
import decor_3 from '../../img/dash_eleventh.png'


 export default function Main() {
    return (
        <div className="price_table first-one">
            <img className="decor_1" src={decor_3} />
            <img className="decor_2" src={decor_3} />
            <h4>Арена</h4>
            <GameTable className="table_game mobile" />
            <Table className="table_game pc" columns={columns_arena} data={arena} />
            <Table className="table_pocket" columns={columns_pocket} data={pocket} />
            <Table className="table_tv pc" columns={columns_tv} data={tv} />
            <TvTable className="table_tv mobile" />
        </div>
    )
}

function GameTable(){
  const [game_type, setGameType] = useState('STANDART');

  const changeGame = (event, type)=>{
    let all_actives = document.querySelectorAll('.first-one ._game .active');
    all_actives.forEach((el)=>{
      el.classList.remove('active');
    })
    event.target.classList.add('active');
    setGameType(type)
  }

  return (
    <div>
      <div className="mobile table_btns _game">
        <button onClick={(e) => {changeGame(e, 'STANDART')}} className="standart active">STANDART</button>
        <button onClick={(e) => {changeGame(e, 'VIP')}}  className="vip">VIP</button>
      </div>
      <ShowStandartGame gametype={game_type} />
      <ShowVipGame gametype={game_type} />
    </div>
  )
}

function ShowStandartGame(props){
  if(props.gametype=="STANDART"){
    return (
      <Table className="table_game mobile" columns={columns_game_standart_mobile} data={arena} />
    )
  }
}
function ShowVipGame(props){
  if(props.gametype=="VIP"){
    return (
      <Table className="table_game mobile" columns={columns_game_vip_mobile} data={arena} />
    )
  }
}

function TvTable(){
  const [tv_type, setTvType] = useState('STANDART');

  const changeTv = (event, type)=>{
    let all_actives = document.querySelectorAll('._tv .active');
    all_actives.forEach((el)=>{
      el.classList.remove('active');
    })
    event.target.classList.add('active');
    setTvType(type)
  }

  return (
    <div>
      <div className="mobile table_btns _tv">
        <button onClick={(e) => {changeTv(e, 'STANDART')}} className="standart active">STANDART</button>
        <button onClick={(e) => {changeTv(e, 'VIP')}}  className="vip">VIP</button>
      </div>
      <ShowStandartTv tvtype={tv_type} />
      <ShowVipTv tvtype={tv_type} />
    </div>
  )
}
function ShowStandartTv(props){
  if(props.tvtype=="STANDART"){
    return (
      <Table className="table_tv mobile" columns={columns_standart_tv} data={tv} />
    )
  }
}
function ShowVipTv(props){
  if(props.tvtype=="VIP"){
    return (
      <Table className="table_tv mobile" columns={columns_vip_tv} data={tv} />
    )
  }
}

const columns_game_standart_mobile = [
  {
    title: 'ИГРА',
    dataIndex: 'game',
    key: 'game',
    className: 'first_title',
  },
  {
    title: 'будни',
    dataIndex: 'weekday',
    key: 'weekday',
    className: 'blago_white left',
  },
  {
    title: 'выходные',
    dataIndex: 'weekend',
    key: 'weekend',
    className: 'blago_white right',
  },
]
const columns_game_vip_mobile = [
  {
    title: 'ИГРА',
    dataIndex: 'game',
    key: 'game',
    className: 'first_title',
  },
  {
    title: 'будни',
    dataIndex: 'weekday_vip',
    key: 'weekday_vip',
    className: 'blago_red left',
  },
  {
    title: 'выходные',
    dataIndex: 'weekend_vip',
    key: 'weekend_vip',
    className: 'last_title',
    className: 'blago_red right',
  }
]
const columns_arena = [
    {
      title: 'ИГРА',
      dataIndex: 'game',
      key: 'game',
      className: 'first_title',
    },
    {
      title: 'будни',
      dataIndex: 'weekday',
      key: 'weekday',
      className: 'blago_white left',
    },
    {
      title: 'STANDART',
      dataIndex: '',
      key: '',
      className: 'blago_white center',
    },
    {
      title: 'выходные',
      dataIndex: 'weekend',
      key: 'weekend',
      className: 'blago_white right',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      className: 'marding',
    },
    {
      title: 'будни',
      dataIndex: 'weekday_vip',
      key: 'weekday_vip',
      className: 'blago_red left',
    },
    {
      title: 'VIP',
      className: 'decor_cell',
      className: 'blago_red center',
    },
    {
      title: 'выходные',
      dataIndex: 'weekend_vip',
      key: 'weekend_vip',
      className: 'last_title',
      className: 'blago_red right',
    }
  ];
  const arena = [
    { game: '', weekday: '', weekend: '', weekday_vip: '', weekend_vip: '',},
    { game: '1 ЧАС', weekday: '5-7 BYN', weekend: '5-8 BYN', weekday_vip: '9 BYN', weekend_vip: '10 BYN',},
    { game: '3 ЧАСА', weekday: '9-13 BYN', weekend: '11-21 BYN', weekday_vip: '24 BYN', weekend_vip: '27 BYN',},
    { game: '5 ЧАСОВ', weekday: '13-20 BYN', weekend: '16-25 BYN', weekday_vip: '35 BYN', weekend_vip: '40 BYN',},
    { game: '8 ЧАСОВ', weekday: '22 BYN', weekend: '26 BYN', weekday_vip: '37 BYN', weekend_vip: '42 BYN',},
  ];

  const NameComponent = ({ day }) => (
    <p dangerouslySetInnerHTML={{ __html: day }} />
  );

  const columns_pocket = [
    {
      title: 'ПАКЕТЫ',
      dataIndex: 'day',
      key: 'day',
      className: 'first_title',
      render: (text) => <NameComponent day={text} />,
    },
    {
      title: 'будни',
      dataIndex: 'weekday',
      key: 'weekday',
      className: 'blago_white left',
    },
    {
      title: 'STANDART',
      dataIndex: '',
      key: '',
      className: 'blago_white center',
    },
    {
      title: 'выходные',
      dataIndex: 'weekend',
      key: 'weekend',
      className: 'blago_white right',
    }
  ];
  const pocket = [
  { day: '', time: '', weekday: '', weekend: ''},
  { day: 'УТРО<span>06:00-10:00</span>', weekday: '9-13 BYN', weekend: '11-16 BYN'},
  { day: 'ДЕНЬ<span>10:00-17:00</span>', weekday: '11-16 BYN', weekend: '14-19 BYN'},
  { day: 'ВЕЧЕР<span>17:00-22:00</span>', weekday: '13-20 BYN', weekend: '16-25 BYN'},
  { day: 'НОЧЬ<span>22:00-06:00</span>', weekday: '17-22 BYN', weekend: '21-26 BYN'},
  { day: 'СУТКИ<span>24 часа</span>', weekday: '75 BYN', weekend: '75 BYN'},
  ];
  const columns_tv = [
    {
      title: 'TV/PS5',
      dataIndex: 'game',
      key: 'game',
      className: 'first_title',
    },
    {
      title: 'будни',
      dataIndex: 'weekday',
      key: 'weekday',
      className: 'blago_white left',
    },
    {
      title: 'TV',
      dataIndex: '',
      key: '',
      className: 'blago_white center',
    },
    {
      title: 'выходные',
      dataIndex: 'weekend',
      key: 'weekend',
      className: 'blago_white right',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      className: 'marding',
    },
    {
      title: 'будни',
      dataIndex: 'weekday_vip',
      key: 'weekday_vip',
      className: 'blago_red left',
    },
    {
      title: 'TV VIP',
      className: 'decor_cell',
      className: 'blago_red center',
    },
    {
      title: 'выходные',
      dataIndex: 'weekend_vip',
      key: 'weekend_vip',
      className: 'last_title blago_red right',
    }
  ];
  const tv = [
    { game: '', weekday: '', weekend: '', weekday_vip: '', weekend_vip: '',},
    { game: '1 ЧАС', weekday: '10 BYN', weekend: '10 BYN', weekday_vip: '20 BYN', weekend_vip: '20 BYN',},
    { game: '3 ЧАСА', weekday: '25 BYN', weekend: '25 BYN', weekday_vip: '50 BYN', weekend_vip: '50 BYN',},
  ];
  const columns_standart_tv = [
    {
      title: 'TV/PS5',
      dataIndex: 'game',
      key: 'game',
      className: 'first_title',
    },
    {
      title: 'будни',
      dataIndex: 'weekday',
      key: 'weekday',
      className: 'blago_white left',
    },
    {
      title: 'выходные',
      dataIndex: 'weekend',
      key: 'weekend',
      className: 'blago_white right',
    },
  ];
  const columns_vip_tv = [
    {
      title: 'TV/PS5',
      dataIndex: 'game',
      key: 'game',
      className: 'first_title',
    },
    {
      title: 'будни',
      dataIndex: 'weekday_vip',
      key: 'weekday_vip',
      className: 'blago_red left',
    },
    {
      title: 'выходные',
      dataIndex: 'weekend_vip',
      key: 'weekend_vip',
      className: 'last_title blago_red right',
    }
  ];
  