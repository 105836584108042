import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import Arena from './sections/Arena';
import Comp from './sections/Comp';
import Widget from './sections/Widget';
import Game from './sections/Games';
import News from './sections/News';

import vid from '../img/vid_club.mp4';
import about from '../img/about.jpg';
import decor from '../img/dash_first.png';
import decor_1 from '../img/dash_tenth.png';
import decor_2 from '../img/dash_fouth.png';
import decor_3 from '../img/dash_eleventh.png';
import decor_4 from '../img/dash_second.png';
import decor_5 from '../img/dash_eighth.png';
import decor_6 from '../img/dash_seventh.png';
import pin_1 from '../img/pin-1.png';
import pin_2 from '../img/pin-2.png';
import pin_3 from '../img/pin-3.png';

export default function Main() {
  const videoRef = useRef(null);

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  return (
    <div className="main_page">
      <section className="main">
      <video className="main_video"
            autoplay=""
            muted={true}
            loop
            playsinline=""
            onLoadedMetadata={handlePlay}
            src={vid}>
      </video>
        <img className="main_decor" src={decor} />
        <div className="main_content">
          <h2 className="avatar">aVatar</h2>
          <h4>ТВОЙ ПУТЬ В КИБЕРСПОРТ</h4>
          <Link to="/about">
            <button>Про клубы</button>
          </Link>

          <p>
            Уникальные киберпространства с мощным железом, топовыми девайсами и
            атмосферой, настраивающей на победу
          </p>
        </div>
      </section>
      <section className="bar"></section>
      <section className="tournament_header">
        <div className="tournament_blur"></div>
        <a href="https://t.me/AVTournamentsBot">
          <h4>РЕГИСТРАЦИЯ НА ТУРНИРЫ</h4>
        </a>
      </section>
      <section className="about">
        <img className="decor" src={decor_1} />
        <img className="decor" src={decor_2} />
        <img className="decor" src={decor_3} />
        <img className="decor" src={decor_3} />
        <h3>О НАС</h3>
        <div className="about_wrapper">
          <img src={about} />
          <div className="about_content">
            <div className="about_text">
              <p>
                Самая большая киберспортивная АРЕНА в Бресте, работающая 24/7, в
                которой слово «сервис» стоит на первом месте! Мы хотим, чтобы вы
                были для нас не просто гостем, а частью AVATAR Community. Для
                этого мы создали команду, которая в первую очередь заботится о
                Вас, старается учесть все ваши предпочтения и пожелания.
                <ul>
                  <li>Просторные VIP комнаты;</li>
                  <li>Комфортная lounge-зона с PS5 и бар;</li>
                  <li>Еженедельные розыгрыши;</li>
                  <li>Возможность стать частью киберспортивной команды.</li>
                </ul>
              </p>
            </div>
            <div className="about_link_wrap">
              <Link className="about_link" to="/rules">
                ПРАВИЛА ПОСЕЩЕНИЯ
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="why">
        <img className="decor" src={decor_1} />
        <img className="decor" src={decor} />
        <img className="decor" src={decor_3} />
        <h3>ПОЧЕМУ ВЫБИРАЮТ</h3>
        <h2 className="avatar">aVatar</h2>
        <div className="why_wrapper">
          <div className="why_block">
            <img src={pin_1} />
            <p>
              Большая киберспортивная арена в Бресте! 300 квадратных метров
              киберспортивного пространства!
            </p>
          </div>
          <div className="why_block">
            <img src={pin_2} />
            <p>
              Более 80 мощнейших игровых компьютеров: 3060, 3070 и даже 4070TI!
            </p>
          </div>
          <div className="why_block">
            <img src={pin_3} />
            <p>
              Еженедельные киберспортивные турниры с большим призовым фондом!
            </p>
          </div>
        </div>
      </section>

      <Widget />

      <Arena />

      <section className="bar_2"></section>
      <section className="price">
        <div className="price_wrapper">
          <div className="price_content">
            <h3>ПРАЙС</h3>
            <p>
              Наши цены подойдут всем типам игроков, которые предпочитают играть
              в разное время суток. При этом лучше отдать предпочтение пакету
              «Сутки». Так у вас не будет абсолютно никаких игровых ограничений и
              вы сможете хорошо сэкономить.
            </p>
            <div className="price_link">
              <Link to="/price">ПОСМОТРЕТЬ ЦЕНЫ</Link>
            </div>
          </div>
          <div className="price_slider">
            <div className="slider_block">
              <p>
                Дневной
                <br />
                <span>от 5 р/час</span>
              </p>
            </div>
            <div className="slider_block">
              <p>
                Пакеты
                <br />
                <span>от 10 р/час</span>
              </p>
            </div>
            <div className="slider_block">
              <p>
                Аренда TV
                <br />
                <span>от 10 р/час</span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Comp />
      <Game />
      <News />
    </div>
  );
}
