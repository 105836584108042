import React from 'react'
import { Link } from 'react-router-dom';

import news1 from '../../img/news_1.jpg'
import news2 from '../../img/news_2.jpg'
import news3 from '../../img/news_3.jpg'
import news4 from '../../img/news_4.jpg'

import decor from '../../img/dash_first.png'
import decor_3 from '../../img/dash_eleventh.png'

export default function Main() {
    return (
        <section className="news">
        <img className="decor" src={decor} />
        <img className="decor" src={decor} />
        <img className="decor" src={decor_3} />
          <h3>ПОСЛЕДНИЕ НОВОСТИ</h3>
          <div className="news_wrap">
            <div className="news_block">
              <img src={news1} />
              <div className="news_block_content">
                <h5>🏆 Турниры в августе в нашем
                компьютерном клубе!</h5>
                <p> Новый турнирный сезон в AVATAR Дисциплины на месяц...</p>
                <span>01.08.2024</span>
              </div>
            </div>
            <div className="news_block">
              <img src={news2} />
              <div className="news_block_content">
                <h5>Проводим мероприятия под ключ</h5>
                <p>AVATAR полностью позаботится о вашем мероприятии</p>
                <span>20.07.2024</span>
              </div>
            </div>
            <div className="news_block">
              <img src={news3} />
              <div className="news_block_content">
                <h5>НОВАЯ ВАЛЮТА КЛУБА - AVATAR COIN</h5>
                <p> Пиобретая любой товар 
                  или игровое время, ты получаешь КОИНЫ</p>
                <span>11.07.2024</span>
              </div>
            </div>
            <div className="news_block">
              <img src={news4} />
              <div className="news_block_content">
                <h5>ИГРОВЫЕ ПОДПИСКИ</h5>
                <p>🔥 Для твоего удобства, 
                  мы разработали игровые...</p>
                <span>10.07.2024</span>
              </div>
            </div>
          </div>
          <Link to="/news">ВСЕ НОВОСТИ</Link>
        </section>
    )
}
