import React from 'react'
import logo from '../img/logo_com.png'
import time from '../img/dash_worktime.png'
import { Link } from 'react-router-dom';

export default function Header() {
    return (
        <header>
            <div className="header_wrapper">
                <div className="logo">
                    <Link to="/">
                        <img src={logo} />
                    </Link>
                </div>
                <nav>
                    <Link to="/about">О нас</Link>
                    <Link to="/price">Цены</Link>
                    <Link to="/games">Игры</Link>
                    <Link to="/news">Новости</Link>
                    <Link to="/contacts">Забронировать</Link>
                    <a href="https://t.me/AVTournamentsBot">Турниры</a>
                </nav>
                <img className="time" src={time} />
            </div>
            <div className='presentation'></div>
        </header>
    )
}
